<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} department`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>


        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-text-field outlined dense v-model="department.title" label="Department title"></v-text-field>
              <span class="text-danger" v-if="$v.department.title.$error">This information is required</span>
            </div>


            <div class="col-3  mt-5">
              <v-switch label="Status" v-model="department.is_active">

              </v-switch>
            </div>

            <div class="col-12 text-right mt-4">
              <v-btn
                      class="btn btn-standard text-gray cancel-btn"
                      depressed
                      @click="cancelForm"
              >Cancel
              </v-btn>
              <v-btn v-if="checkIsAccessible('department', 'create')"
                      class="text-white ml-2 btn btn-primary"
                      depressed
                      @click="createAndUpdate"
                      :loading="isBusy"
              >Save
              </v-btn>

            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import DepartmentService from "@/core/services/department/DepartmentService";

const departmentService = new DepartmentService();

export default {
  props: ["message_id"],
  name: "department-create-and-update",
  validations: {
    department: {
      title: {required}
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      dialog: false,
      department: {
        title: null,
        is_active: true
      }
    };
  },
  methods: {
    getFaculty(id) {
      departmentService.show(id).then(response => {
        this.edit = true;
        this.department = response.data.department;
        this.department.is_active = this.department.is_active ? true : false
      });
    },
    cancelForm(){
      this.resetForm()
      this.hideModal()
    },  
    showModal(id = null) {
      if (id) {
        this.getFaculty(id);
      }else{
        this.resetForm()
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    resetForm(){
      this.$v.$reset();
      this.teacher = [];
      this.edit = false
      this.dialog = false;
      this.department={
        title: null,
        is_active: true
      }
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.department.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateFaculty();
        } else {
          this.createFaculty();
        }
      }
    },
    createFaculty() {
      this.isBusy = true;
      departmentService.store(this.department).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.hideModal()
        this.$emit("refresh");
      });
    },
    updateFaculty() {
      this.isBusy = true;
      departmentService.update(this.department.id, this.department).then(response => {
        this.isBusy = false;
        
        
        this.$emit("refresh");
      });
    }
  }
};
</script>
<style>
.bg-red {
  background-color: red !important;
}

.v-application--wrap {
  min-height: 0 !important;
}
</style>
