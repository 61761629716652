<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage departments</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Departments
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    v-if="checkIsAccessible('department', 'create')"
                    @click="openDialog()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    department
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    dense
                    outlined
                    v-on:keyup.enter="getDepartments()"
                    v-model="search.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    outlined
                    dense
                    v-on:keyup.enter="getDepartments()"
                    :items="select_items"
                    v-model="search.active"
                    item-text="title"
                    item-value="value"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    @click.prevent="getDepartments()"
                    class="btn btn-primary btn-block w-35 float-right"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-1 wrap-column">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(department, index) of departments" :key="index">
                    <td class="px-3 wrap-column" scope="row">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>
                      <a href="#" @click="openDialog(department.id)" class="mr-2">
                        {{ department.title }}</a
                      >&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="department.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>

                    <td>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('department', 'edit')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="openDialog(department.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text
                            tag="div"
                            class="navi-item"
                            v-if="checkIsAccessible('department', 'delete')"
                          >
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteFaculty(department.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="departments.length == 0">
                    <td class="px-3 text-center" colspan="2">No data available to display.</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="departments.length > 0"
                class="pull-right mt-7"
                @input="getDepartments()"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <create-and-update
            ref="department"
            :department_id="department_id"
            @close_dialog="closeDialog"
            @refresh="getDepartments"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import DepartmentService from "@/core/services/department/DepartmentService";

import CreateAndUpdate from "./CreateAndUpdate";

const departmentService = new DepartmentService();

export default {
  name: "Department",
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      loading: false,
      departments: [],
      department_id: null,
      page: null,
      total: null,
      perPage: null,
      select_items: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in-active" },
      ],
      search: {
        active: "active",
      },
    };
  },
  mounted() {
    this.getDepartments();
  },
  methods: {
    getDepartments() {
      this.loading = true;
      departmentService
        .paginate(this.search)
        .then((response) => {
          this.departments = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
          this.$snotify.error("Oops looks like something went wrong.");
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialog(id) {
      this.department_id = id;
      this.$refs["department"].showModal(id);
    },
    closeDialog(id) {
      this.department_id = id;
      this.$refs["department"].hideModal();
      this.department_id = null;
      this.getDepartments();
    },
    deleteFaculty(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            departmentService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getDepartments();
            });
          }
        },
      });
    },
  },
};
</script>
